<template>
  <div role="main" style="height: calc(100vh - 105px)">
    <div id="itemBody" class="itemBody" data-section="true">
      <div class="container">
        <div class="instructions">
          <h1>IELTS Academic Writing</h1>
          <!--<div connect:class="testTitle">
            <h2>Writing</h2>
          </div>-->
          <div class="preTestInfo">
            <p>Time: 1 hour</p>
            <h2>INSTRUCTIONS TO CANDIDATES</h2>
            <ul>
              <li>Answer <b>both</b> parts.</li>
              <li>You can change your answers at any time during the test.</li>
            </ul>
            <h2>INFORMATION FOR CANDIDATES</h2>
            <ul>
              <li>There are two parts in this test.</li>
              <li>Part 2 contributes twice as much as Part 1 to the writing score.</li>
              <li>The test clock will show you when there are 10 minutes and 5 minutes remaining.</li>
            </ul>
          </div>
          <div class="textCentre">
            <p class="info central">Do not click 'Start test' until you are told to do so.</p>
              <input type="submit" id="instructionsConfirm" value="Start test" class="button green" @click="$router.push({name: 'apps-academic-writing-main'})"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WritingInstruction"
}
</script>


